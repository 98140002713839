import {Grid, Typography, Box, Button, ButtonGroup, MenuItem, Menu} from "@mui/material";
import CampaignCard from "../../card/campaign-card/CampaignCard";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import {LoadingButton} from "@mui/lab";
import { useNavigate } from "react-router-dom";
import AudienceService from "../../../../services/api/audience.service";
import { baseUrl } from "../../../../config/config";
import {setCampaignAudiences, setTotalSelectedAudience} from "../../../../redux/slices/audience";
import styles from "../../card/audience/AudienceCard.module.scss";
import NewActivateConfirmModal from "../../modal/new-activate-confirm-modal/NewActivateConfirmModal";
import StripeService from "../../../../services/api/stripe.service";
import ActivateTopUpCreditModal from "../../modal/activate-top-credit-modal/ActivateTopUpCreditModal";
import {setMessage} from "../../../../redux/slices/message";
import {setMessageSnackbarStateState} from "../../../../redux/slices/common";

const CampaignDetails = ({ audienceDetails, connectorCount, connectorData }) => {

    const { user } = useSelector((state) => state.auth.auth);
    const { selectedCountry } = useSelector((state) => state.auth);
    const { currentTheme } = useSelector((state) => state.theme);
    const { campaignAudiences, totalSelectedAudience } = useSelector((state) => state.audience);
    const [ audienceData, setAudienceData] = useState([]);
    const [ amountDetails, setAmountDetails] = useState([]);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const menuOpen = Boolean(menuAnchorEl);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [newActivateConfirmModalState, setNewActivateConfirmModalState] = useState(false);
    const [activateTopUpCreditModalState, setActivateTopUpCreditModalState] = useState(false);

    const userFromLocalStorage = JSON.parse(localStorage.getItem('user'));

    const planMode = user?.planMode || userFromLocalStorage?.planMode;


    useEffect(() => {
        let mounted = true;
        // console.log("audienceHistoryChange", audienceHistoryChange);
        if (mounted && user && selectedCountry) {
            const queryParams = [
                `userId=${user.id}`,
                `countryId=${selectedCountry.id}`,
            ];

            if(audienceDetails?.audienceId && audienceDetails.audienceId) {
                queryParams.push(`audienceId=${audienceDetails.audienceId}`);

                const isAddAudiencesCount = campaignAudiences.filter(audience => audience.isAdd).reduce((acc, audience) => acc + audience.totalAudienceCount, 0);

                // Calculate the total audience count
                const totalAudienceCount = isAddAudiencesCount + audienceDetails.audience;

                dispatch(setTotalSelectedAudience(totalAudienceCount));
            }

            // fetch audiences
            AudienceService.getCampaignAudience(queryParams.join('&'))
                .then((data) => {
                    console.log('from favourite page');
                    const audienceList = data.results.reverse();
                    // console.log("audienceFilteredList", audienceFilteredList);
                    if (audienceList.length > 0) {
                       setAudienceData(audienceList);
                    } else {
                        setAudienceData([]);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            const queryCalcParams = [
                `userId=${user.id}`,
                `countryId=${selectedCountry.id}`,
            ];

            if(audienceDetails?.audienceId && audienceDetails.audienceId) {

                queryCalcParams.push(`audienceCount=${totalSelectedAudience}`);
            }

            queryCalcParams.push(`connectorsCount=${1}`);


            StripeService.getAmount(queryCalcParams.join('&')).then((data) => {

                    if (data?.results) {
                        setAmountDetails(data.results);
                    }
                    return Promise.resolve();
                }, (error) => {
                    return Promise.reject();
                }
            )
        }

        return () => {
            mounted = false;
        }
    }, [user, selectedCountry]);



    const top100Films = [
        { title: 'The Shawshank Redemption', year: 1994 },
        { title: 'The Godfather', year: 1972 },
        { title: 'The Godfather: Part II', year: 1974 },
        { title: 'The Dark Knight', year: 2008 },
        { title: '12 Angry Men', year: 1957 },
        { title: "Schindler's List", year: 1993 },
        { title: 'Pulp Fiction', year: 1994 },
        {
            title: 'The Lord of the Rings: The Return of the King',
            year: 2003,
        },
        { title: 'The Good, the Bad and the Ugly', year: 1966 },
        { title: 'Fight Club', year: 1999 }]

    const defaultProps = {
        options: top100Films,
        getOptionLabel: (option) => option.title,
    };


    const handleClose = (e, reason) => {
        dispatch(setTotalSelectedAudience(0));
        dispatch(setCampaignAudiences([]));

        if (reason !== "backdropClick") {
            if (audienceDetails && audienceDetails.audienceId) {
                navigate('/audience/'+ audienceDetails.audienceId);
            } else {
                navigate('/Home');
            }
        }
    };

    const handleSearchChange = (event) => {

        const searchText = event.target.value.toLowerCase();

        if (audienceData?.length && searchText.length > 1) {
            const filteredAudList = audienceData.filter((aud) =>
                aud.name.toLowerCase().includes(searchText)
            );

            // Merge and filter out duplicates based on id
            const updatedAudiences = [...campaignAudiences, ...filteredAudList]
                .reduce((acc, cur) => {
                    if (!acc.some(item => item.id === cur.id)) {
                        acc.push(cur);
                    }
                    return acc;
                }, []);

            dispatch(setCampaignAudiences(updatedAudiences));
        } else {

            const filteredAudList = campaignAudiences.filter((aud) => aud.isAdd);
            dispatch(setCampaignAudiences(filteredAudList));
        }
    }

    const handleAudienceConfirmClick = () => {

        if (!totalSelectedAudience > 0) {
            dispatch(setMessage({
                type: "error",
                title: "Please Select a card to activate audiences",
            }));
            dispatch(setMessageSnackbarStateState(true));
            return;
        }

        if (planMode === 'Trial'  &&  totalSelectedAudience > amountDetails.audienceActivatedLimitAvailable) {

            setActivateTopUpCreditModalState(true);

        } else if (planMode === 'credit' && totalCost > amountDetails.totalCreditAvailable) {

            setActivateTopUpCreditModalState(true);
        } else {


            if(connectorCount === 0) {
                dispatch(setMessage({
                    type: "error",
                    title: "Please select a connector",
                }));
                dispatch(setMessageSnackbarStateState(true));
                return;
            }
            setNewActivateConfirmModalState(true);
        }
    }

    const calculateCost = (totalAudience, costPerThousand) => {
        // Calculate total cost
        return ((totalAudience / 1000) * costPerThousand).toFixed(2);
    };

    const audienceMultiplier = connectorCount === 0 ? 1 : connectorCount;
    const totalAudience = totalSelectedAudience * audienceMultiplier;
    const totalCost = calculateCost(totalAudience, amountDetails.costPerAudience);



    return (<>
        <Box
            style={{
                paddingTop: '15px',
                paddingLeft: "15px",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                minHeight: '100vh'
            }}
        >
            {audienceDetails && audienceDetails.audienceId && (
                <Box style={{paddingTop: '15px', paddingRight: '5px', paddingLeft: '15px'}}>
                    <Typography style={{ fontFamily: "Manrope, Arial, sans-serif", marginTop: "2px", fontSize: "22px", fontWeight: 700, lineHeight: "43.71px", textAlign: "left" }} gutterBottom>
                        Campaign Details
                    </Typography>
                    <Grid>
                        <Grid sx={{ paddingTop: "5px" }}>
                            <CampaignCard isClose={true} audienceDetails={audienceDetails}/>
                        </Grid>
                    </Grid>
                </Box>
            )}

            <Box style={{ paddingLeft: "15px", paddingTop: audienceDetails && audienceDetails.audienceId ? '0px' : '15px' }}>
                <Typography style={{ fontFamily: "Manrope, Arial, sans-serif", fontSize: audienceDetails && audienceDetails.audienceId ? "14px" : "20px", fontWeight: 700, lineHeight: "43.71px", textAlign: "left" }} gutterBottom>
                    {audienceDetails && audienceDetails.audienceId ? 'Additional campaign audience' : 'Select Audience' }
                </Typography>
                <TextField variant="standard" placeholder='Type to search' inputProps={{ style: { color: currentTheme === 'dark' ? '#ffffff' : undefined   } }} style={{ width: "300px", height: "45px" }} onChange={handleSearchChange} />
                <Box className="SliderBox"
                     style={{ height: audienceDetails && audienceDetails.audienceId ? "310px" :"490px", width: "100%",
                         overflowY: "auto", overflowX: "hidden",
                         paddingTop: '5px',
                         borderRadius: '20px',
                     }}>
                    <Grid container>
                        {campaignAudiences && campaignAudiences.map((data, index) => (
                            <Grid item key={index} xs={12} sx={{ paddingTop: "10px" }}>
                                <CampaignCard audienceDetails={data}/>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>

            <Box className="last" style={{ paddingBottom: '25px' }} >
                <Box style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                { planMode === 'Trial' ? (
                   <>
                    <Box>
                        <Typography style={{ fontFamily: "Manrope, Arial, sans-serif", fontSize: "14px", fontWeight: 200, lineHeight: "20.71px", textAlign: "left" ,  color: currentTheme === 'dark' ? '#ffffff' : undefined }} gutterBottom>
                            Available Audience Credits
                        </Typography>
                        <Typography style={{ fontFamily: "Manrope, Arial, sans-serif", marginTop: "1px", fontSize: "14px", fontWeight: 200, textAlign: "left",  color: currentTheme === 'dark' ? '#ffffff' : undefined  }} gutterBottom>
                            Selected Audience
                        </Typography>
                    </Box>
                    <Box style={{ marginLeft: '125px' }}>
                        <Typography style={{ fontFamily: "Manrope, Arial, sans-serif", marginTop: "5px", fontSize: "14px", fontWeight: 700, lineHeight: "20.71px", color: '#39BF8F', textAlign: "left" }} gutterBottom>
                            { amountDetails.audienceActivatedLimitAvailable !== undefined ? amountDetails.audienceActivatedLimitAvailable.toLocaleString() : '0' }
                        </Typography>
                        <Typography style={{ fontFamily: "Manrope, Arial, sans-serif", marginTop: "5px", fontSize: "14px", fontWeight: 700, lineHeight: "20.71px", color: (totalSelectedAudience * (connectorCount === 0 ?  1 : connectorCount)) > (amountDetails.audienceActivatedLimitAvailable || 0) ? '#C02026' : '#39BF8F', textAlign: "left" }} gutterBottom>
                            { (totalSelectedAudience * (connectorCount === 0 ?  1 : connectorCount)) ? (totalSelectedAudience * (connectorCount === 0 ?  1 : connectorCount)).toLocaleString() : '0' }
                        </Typography>
                    </Box>
                   </>
                    ) : (
                        <>
                    <Box>
                        <Typography style={{ fontFamily: "Manrope, Arial, sans-serif", fontSize: "14px", fontWeight: 200, lineHeight: "20.71px", textAlign: "left" ,  color: currentTheme === 'dark' ? '#ffffff' : undefined }} gutterBottom>
                            Available Credits
                        </Typography>
                        <Typography style={{ fontFamily: "Manrope, Arial, sans-serif", marginTop: "1px", fontSize: "14px", fontWeight: 200, textAlign: "left",  color: currentTheme === 'dark' ? '#ffffff' : undefined  }} gutterBottom>
                            Selected Audience by Credits
                        </Typography>
                    </Box>
                    <Box style={{ marginLeft: '125px' }}>
                     <Typography style={{ fontFamily: "Manrope, Arial, sans-serif", marginTop: "5px", fontSize: "14px", fontWeight: 700, lineHeight: "20.71px", color: '#39BF8F', textAlign: "left" }} gutterBottom>
                         { amountDetails.totalCreditAvailable !== undefined ? `$ ${amountDetails.totalCreditAvailable.toLocaleString()}` : '$0'}
                     </Typography>
                        <Typography style={{ fontFamily: "Manrope, Arial, sans-serif", marginTop: "5px", fontSize: "14px", fontWeight: 700, lineHeight: "20.71px", color: totalCost > (amountDetails.totalCreditAvailable || 0) ? '#C02026' : '#39BF8F', textAlign: "left",}} gutterBottom
                        >
                            {`$ ${totalCost}`}
                        </Typography>
                    </Box>
                        </> )}
                </Box>
                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <Button
                        variant='contained'
                        fullWidth
                        type='submit'
                        className="form-button"
                        sx={{ textTransform: "none", borderRadius: "8px" }}
                        onClick={handleAudienceConfirmClick}
                    >
                        SUBMIT
                    </Button>
                </Box>
            </Box>
        </Box>




            <NewActivateConfirmModal
                audience={audienceDetails}
                newActivateConfirmModalState={newActivateConfirmModalState}
                setNewActivateConfirmModalState={setNewActivateConfirmModalState}
                connectorData={connectorData}
                connectorCount={connectorCount}
            />


        {activateTopUpCreditModalState &&
            <ActivateTopUpCreditModal
                activateTopUpCreditModalState={activateTopUpCreditModalState}
                setActivateTopUpCreditModalState={setActivateTopUpCreditModalState}
            />
        }

    </>);
}


export default CampaignDetails;