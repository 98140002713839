import React, { useEffect, useState } from "react";
import { Box, Button, Checkbox, Collapse, Radio } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import styles from './SearchFilter.module.scss';
import { useNavigate } from "react-router-dom";
import Slider from '@mui/material/Slider';
import Image from "mui-image";
import { useDispatch, useSelector } from "react-redux";
import Brightness1Icon from '@mui/icons-material/Brightness1';
import Brightness1OutlinedIcon from '@mui/icons-material/Brightness1Outlined';

import {
  setCreateAudienceModalState,
  setMessageSnackbarStateState,
  setUploadAudienceModalState
} from "../../../../redux/slices/common";
import locationCircle from '../../../../assets/images/icons/svg/location-circle.svg';
import glasses from '../../../../assets/images/icons/svg/glasses.svg';
import { Export, Location, Setting4 } from "iconsax-react";
import LinearProgress from '@mui/material/LinearProgress';
import PoiService from "../../../../services/api/poi.service";
import { formatToGeoJson } from "../../../../utils/map";
import { setMapLoading, setMapPoints } from "../../../../redux/slices/map";
import {
  setAudienceIdsList,
  setAudienceLimit,
  setDefaultSearchKeywords,
  setDefaultSliderValues,
  setEstimateAudiences,
  setFilterMoreGroups,
  setPartnerPOIList,
  setPlacesList,
  setPoiIdsList,
  setBrandIdList,
  setSliderValues,
  setTotalPlaces
} from "../../../../redux/slices/audience";
import AudienceService from "../../../../services/api/audience.service";
import { setMessage } from '../../../../redux/slices/message';
import { getPermission } from "../../../../utils/cryptograpgy";
import ActivateTopUpCreditModal from "../../modal/activate-top-credit-modal/ActivateTopUpCreditModal";
import StripeService from "../../../../services/api/stripe.service";

const PrettoSlider = styled(Slider)({
  color: '#fc6b21',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-mark': {
    width: '10px',
    height: '10px',
    borderRadius: '20px',
    transform: 'translate(-5px, -50%)',
  },
  '& .MuiSlider-markLabel': {
    color: '#6F7288',
    fontSize: '10px',
  },
  '& .MuiSlider-thumb': {
    height: 16,
    width: 16,
    backgroundColor: '#fff',
    border: '6px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    display: 'none',
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#fc6b21',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

function SearchFilter() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dayParting, setDayParting] = useState([]);
  //const [isPOIDetails, setIsPOIDetails] = useState([]);

  const { selectedCountry } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.auth.auth);
  const mapPoints = useSelector(state => state.map.mapPoints);
  const { audienceLimit, totalPlaces, estimateAudiences, placesList, filterMoreGroups, defaultSliderValues, sliderValues, poiIdsList, audienceIdsList, partnerPOIList, brandIdList } = useSelector((state) => state.audience);
  const { currentTheme } = useSelector((state) => state.theme);
  const [filterResultOpen, setFilterResultOpen] = useState(true);
  const [filterMoreOptionOpen, setFilterMoreOptionOpen] = useState(true);
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  const [isTotalPlacesLoading, setIsTotalPlacesLoading] = useState(false);
  const [isMergeFilter, setIsMergeFilter] = useState(false);
  const [colorIndex, setColorIndex] = useState(0);
  const userFromLocalStorage = JSON.parse(localStorage.getItem('user'));
  const [poiDetails, setPoiDetails] = useState(null);
  const [poiIdsListForFilter, setPoiIdsListForFilter] = useState("");

  const addCard = getPermission('add_card');
  const crumbList = JSON.parse(localStorage.getItem('crumbList'));
  const filteredCrumbList = crumbList.filter(crumb => crumb.isDemoCrumb === null || crumb.isDemoCrumb === false);

  const [activateTopUpCreditModalState, setActivateTopUpCreditModalState] = useState(false);
  const planMode = user?.planMode || userFromLocalStorage?.planMode;

  useEffect(() => {

    return () => {
      localStorage.removeItem('applyFilterParams');
    }
  }, [selectedCountry, user]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      loadAudiencByIds(audienceIdsList);
    }

    return () => {
      mounted = false;
    }
  }, [audienceIdsList]);

  useEffect(() => {
    let mounted = true;
    if (mounted && poiIdsList !== '') {
      loadPoiByIds(poiIdsList);
    }

    return () => {
      mounted = false;
    }
  }, [poiIdsList]);

  useEffect(() => {

    let mounted = true;
    if (mounted && partnerPOIList !== '') {
      loadPartnerPoiIds(partnerPOIList, '');  // Ensure correct usage
    } else if (mounted && brandIdList !== '') {
      loadPartnerPoiIds('', brandIdList);  // Ensure correct usage
    }

    return () => {
      mounted = false;
    };
  }, [partnerPOIList, brandIdList]);


  const poiRejectedIds = mapPoints
      .filter(point => point.properties.isEnabled && (point.properties.typeName === "poi" || point.properties.typeName === null || !point.properties.typeName))
      .map(point => point.properties.id);


  const locationRejectedIds = mapPoints
      .filter(point => point.properties.isEnabled && point.properties.typeName === "loc")
      .map(point => point.properties.id);


  const loadPoiByIds = (poiIdsList) => {

    //setIsPOIDetails(false);
    if (selectedCountry && user && poiIdsList) {
      // dispatch(setPoiIdsList(poiIds));
      dispatch(setMapLoading(true));
      localStorage.removeItem("selectedSearchKeywords");
      dispatch(setDefaultSearchKeywords([]));
      dispatch(setAudienceIdsList(''));
      const queryParams = [
        `poiId=${poiIdsList}`,
        `userId=${user.id}`,
        `countryId=${selectedCountry.id}`,
      ];

      PoiService.loadPoi(queryParams.join('&')).then(
        (data) => {
          if (data?.results) {
            dispatch(setFilterMoreGroups(data.results.audienceFilterConfig.config));

            const defaultOptionValues = data.results.audienceFilterConfig.config.map(group => {
              if (group.title === 'Day Parting') {
                const defaultValues = group.options.filter((option) => option.isDefault === 'true').map((option) => option.value);
                return defaultValues;

              } else {
                const defaultValue = group.options.find(option => option.isDefault === 'true');
                return defaultValue ? defaultValue.value : group.options[0].value;
              }

            });

            setDayParting([0, 1, 2, 3])
            dispatch(setDefaultSliderValues(defaultOptionValues));
            dispatch(setSliderValues(defaultOptionValues));

            const applyFilterParams = {
              visitPeriod: '',
              geoRadius: '',
              weekParting: '',
              dayParting: [],
              audiences: data.results.audience,
              audienceId: ''
            };

            localStorage.setItem('applyFilterParams', JSON.stringify(applyFilterParams));
            dispatch(setEstimateAudiences(data.results.audience));
            dispatch(setTotalPlaces(data.results.total));
            dispatch(setPlacesList(data.results.places));

            setPoiDetails(data.results);
            const geojsonPoints = [];
            if (data.results?.places?.length) {
              const places = data.results.places;
              for (let indexPlaces = 0; indexPlaces < places.length; indexPlaces++) {
                const place = places[indexPlaces];
                if (place?.poiDetails?.length) {
                  const poiDetailsDtos = place.poiDetails;
                  for (let index = 0; index < poiDetailsDtos.length; index++) {
                    const poiDetailsDto = formatToGeoJson(poiDetailsDtos[index], place.colorName, place.colorValue);
                    if (poiDetailsDto) {
                      geojsonPoints.push(poiDetailsDto);
                    }
                  }
                }
              }
            }
            dispatch(setMapPoints(geojsonPoints));
          }
          dispatch(setMapLoading(false));
          setIsFilterLoading(false);
          setIsTotalPlacesLoading(false);

          if (poiIdsList !== undefined) {
            const poiIdsArrayStrings = poiIdsList.split(",");
            const poiIdsArrayNumbers = poiIdsArrayStrings.map(Number);

            let audience = JSON.parse(localStorage.getItem("audience")) || {
              userId: user.id,
              countryId: selectedCountry.id,
              poiId: poiIdsArrayNumbers,
              visitPeriod: 180,
              geoRadius: 5000,
              weekParting: 2,
              dayParting: [0, 1, 2, 3].toString(),
              totalLocationPin: data.results.total,
            };

            // Update the values of the elements in the collection
            audience.poiId = poiIdsArrayNumbers;
            audience.visitPeriod = 180;
            audience.geoRadius = 5000;
            audience.weekParting = 2;
            audience.dayParting = [0, 1, 2, 3].toString();
            audience.totalLocationPin = data.results.total;

            // Store the updated collection back into localStorage
            localStorage.setItem("audience", JSON.stringify(audience));
          }

          return Promise.resolve();
        },
        (error) => {
          // console.log("error", error?.response?.data?.message);
          dispatch(
            setMessage({
              type: "error",
              title: error?.response?.data?.message,
            })
          );
          setIsMergeFilter(false)
          dispatch(setMessageSnackbarStateState(true));
          navigate('/');
          dispatch(setMapLoading(false));
          setIsFilterLoading(false);
          setIsTotalPlacesLoading(false);
          return Promise.reject();
        }
      );
    }
  };

  const loadPartnerPoiIds = (partnerPOIList, brandIdList) => {
    //setIsPOIDetails(true);

    if (selectedCountry && user) {
      // dispatch(setPoiIdsList(poiIds));
      dispatch(setMapLoading(true));
      localStorage.removeItem("selectedSearchKeywords");
      dispatch(setDefaultSearchKeywords([]));
      dispatch(setAudienceIdsList(''));
      dispatch(setPoiIdsList(''));
      const queryParams = [
        `userId=${user.id}`,
        `countryId=${selectedCountry.id}`,
        `isPOIDetailsId=true`
      ];

      if (partnerPOIList !== '') {
        if (partnerPOIList[0].categoryName !== '') {
          queryParams.push(`category=${partnerPOIList[0].categoryName}`);
        }

        // if (partnerPOIList[0].cityName !== '') {
        //   queryParams.push(`city=${partnerPOIList[0].cityName}`);
        // }
      } else {

        queryParams.push(`brandIdList=${brandIdList}`);
      }

      PoiService.loadPoi(queryParams.join('&')).then(
        (data) => {
          if (data?.results) {
            dispatch(setFilterMoreGroups(data.results.audienceFilterConfig.config));

            if (partnerPOIList === '') {

              setColorIndex(data.results.colorName);
            }
            const defaultOptionValues = data.results.audienceFilterConfig.config.map(group => {
              if (group.title === 'Day Parting') {
                return group.options.filter((option) => option.isDefault === 'true').map((option) => option.value);

              } else {
                const defaultValue = group.options.find(option => option.isDefault === 'true');
                return defaultValue ? defaultValue.value : group.options[0].value;
              }

            });

            setDayParting([0, 1, 2, 3])
            dispatch(setDefaultSliderValues(defaultOptionValues));
            dispatch(setSliderValues(defaultOptionValues));

            const applyFilterParams = {
              visitPeriod: '',
              geoRadius: '',
              weekParting: '',
              dayParting: [],
              audiences: data.results.audience,
              audienceId: '',
              //city: data.results.city,
              category: data.results.category,
              isPOIDetailsId: data.results.isPOIDetailsId,
              brandIdList: data.results.brandIdList
            };

            localStorage.setItem('applyFilterParams', JSON.stringify(applyFilterParams));
            dispatch(setEstimateAudiences(data.results.audience));
            dispatch(setTotalPlaces(data.results.total));
            dispatch(setPlacesList(data.results.places));
            dispatch(setPartnerPOIList(''));

            setPoiDetails(data.results);
            const geojsonPoints = [];
            if (data.results?.places?.length) {
              const places = data.results.places;
              for (let indexPlaces = 0; indexPlaces < places.length; indexPlaces++) {
                const place = places[indexPlaces];
                if (place?.poiDetails?.length) {
                  const poiDetailsDtos = place.poiDetails;
                  for (let index = 0; index < poiDetailsDtos.length; index++) {
                    const poiDetailsDto = formatToGeoJson(poiDetailsDtos[index], place.colorName, place.colorValue);
                    if (poiDetailsDto) {
                      geojsonPoints.push(poiDetailsDto);
                    }
                  }
                }
              }
            }
            dispatch(setMapPoints(geojsonPoints));
          }
          dispatch(setMapLoading(false));
          setIsFilterLoading(false);
          setIsTotalPlacesLoading(false);

          if (poiIdsList !== undefined) {
            const poiIdsArrayStrings = poiIdsList.split(",");
            const poiIdsArrayNumbers = poiIdsArrayStrings.map(Number);

            let audience = JSON.parse(localStorage.getItem("audience")) || {
              userId: user.id,
              countryId: selectedCountry.id,
              poiId: poiIdsArrayNumbers,
              visitPeriod: 180,
              geoRadius: 5000,
              weekParting: 2,
              dayParting: [0, 1, 2, 3].toString(),
              totalLocationPin: data.results.total,
            };

            // Update the values of the elements in the collection
            audience.poiId = poiIdsArrayNumbers;
            audience.visitPeriod = 180;
            audience.geoRadius = 5000;
            audience.weekParting = 2;
            audience.dayParting = [0, 1, 2, 3].toString();
            audience.totalLocationPin = data.results.total;

            // Store the updated collection back into localStorage
            localStorage.setItem("audience", JSON.stringify(audience));
          }
          setIsMergeFilter(false)
          return Promise.resolve();
        },
        (error) => {
          // console.log("error", error?.response?.data?.message);
          dispatch(
            setMessage({
              type: "error",
              title: error?.response?.data?.message,
            })
          );
          dispatch(setMessageSnackbarStateState(true));
          navigate('/');
          dispatch(setMapLoading(false));
          setIsFilterLoading(false);
          setIsTotalPlacesLoading(false);
          return Promise.reject();
        }
      );
    }
  };

  const loadAudiencByIds = (audienceIdsList) => {
    //setIsPOIDetails(false);
    if (selectedCountry && user && audienceIdsList) {
      // dispatch(setAudienceIdsList(audienceIds));
      dispatch(setPoiIdsList(""));
      dispatch(setMapLoading(true));
      const queryParams = [
        `audienceIdList=${audienceIdsList.audienceList}`,
        `userId=${user.id}`,
        `countryId=${selectedCountry.id}`,
        `action=${audienceIdsList.action ? audienceIdsList.action : ''}`
        //`rejectedPOIList=${poiRejectedIds}`
      ];
      //`poiIdList=${audienceIdsList.poiList}`,
      if(audienceIdsList.brandList) {

        queryParams.push(`brandIdList=${audienceIdsList.brandList}`);
      }

      if (audienceIdsList.poiList) {

        queryParams.push(`poiIdList=${audienceIdsList.poiList}`);
      }

      AudienceService.mergeAudience(queryParams.join('&')).then(
        (data) => {
          if (data?.results) {

            const dataResults = data.results;
            setColorIndex(data.results.colorName);

            if (dataResults.action === "clone") {
              try {
                // Split the string by commas and convert to an array of strings
                const stringArray = dataResults.dayParting.split(",");

                // Convert the array of strings to an array of integers
                const integerArray = stringArray.map((str) => parseInt(str, 10));

                // Set the integer array as dayParting
                setDayParting(integerArray);
              } catch (error) {
                // Handle parsing errors if necessary
                console.error("Error parsing dayParting:", error);
              }
            }
            else {
              setDayParting([0, 1, 2, 3])
            }


            setPoiDetails(data.results);
            dispatch(setFilterMoreGroups(data.results.audienceFilterConfig.config));

            const applyFilterParams = {
              visitPeriod: dataResults.action === "clone" ? dataResults.visitPeriod : '',
              geoRadius: dataResults.action === "clone" ? dataResults.geoRadius : '',
              weekParting: dataResults.action === "clone" ? dataResults.weekParting : '',
              dayParting: dataResults.action === "clone" ? dataResults.dayParting : '',
              audiences: dataResults.audience,
              audienceId: '',
              brandIdList: data.results.brandIdList,
            };

            localStorage.setItem('applyFilterParams', JSON.stringify(applyFilterParams));

            const defaultOptionValues = data.results.audienceFilterConfig.config.map(group => {
              if (group.title === 'Day Parting') {
                const defaultValues = group.options.filter((option) => option.isDefault === 'true').map((option) => option.value);
                return defaultValues;

              } else {
                const defaultValue = group.options.find(option => option.isDefault === 'true');
                return defaultValue ? defaultValue.value : group.options[0].value;
              }

            });

            dispatch(setDefaultSliderValues(defaultOptionValues));
            dispatch(setSliderValues(defaultOptionValues));

            dispatch(setEstimateAudiences(data.results.audience));
            dispatch(setTotalPlaces(data.results.total));

            setPoiIdsListForFilter(data.results.totalPOIIdList.join(','));

            let allPlaces = [];
            let allAudiences = [];
            if (data.results?.audiences?.length) {
              const audiences = data.results.audiences;
              for (let indexAudiences = 0; indexAudiences < audiences.length; indexAudiences++) {
                const audience = audiences[indexAudiences];
                allAudiences.push({
                  ...audience,
                  placeName: audience.name,
                  count: audience.totalLocationPin
                });
                if (audience?.places?.length) {
                  const places = audience.places;
                  for (let index = 0; index < places.length; index++) {
                    allPlaces.push(places[index]);
                  }
                }
              }

              const geojsonPoints = [];
              if (allPlaces?.length) {
                const places = allPlaces;
                for (let indexPlaces = 0; indexPlaces < places.length; indexPlaces++) {
                  const place = places[indexPlaces];
                  if (place?.poiDetails?.length) {
                    const poiDetailsDtos = place.poiDetails;
                    for (let index = 0; index < poiDetailsDtos.length; index++) {
                      const poiDetailsDto = formatToGeoJson(poiDetailsDtos[index], place.colorName, place.colorValue);
                      if (poiDetailsDto) {
                        geojsonPoints.push(poiDetailsDto);
                      }
                    }
                  }
                }
              }
              // console.log("geojsonPoints", geojsonPoints);
              dispatch(setMapPoints(geojsonPoints));
            }
            dispatch(setPlacesList(allAudiences));
          }
          dispatch(setMapLoading(false));
          setIsFilterLoading(false);
          setIsTotalPlacesLoading(false);
          setIsMergeFilter(true)
          return Promise.resolve();
        },
        (error) => {
          // console.log("error", error?.response?.data?.message);
          dispatch(
            setMessage({
              type: "error",
              title: error?.response?.data?.message,
            })
          );
          dispatch(setMessageSnackbarStateState(true));
          navigate('/');
          dispatch(setMapLoading(false));
          setIsFilterLoading(false);
          setIsTotalPlacesLoading(false);
          return Promise.reject();
        }
      );
    }
  };

  const handleUploadAudience = () => {
    dispatch(setUploadAudienceModalState(true));
  };

  const handleSaveAudience = async () => {

    if(user.isExternalUser || user.isInternalUser) {

      try {
        const queryCalcParams = [
          `userId=${user.id}`,
          `countryId=${selectedCountry.id}`,
        ];

        const data = await StripeService.getUserPlanDetails(queryCalcParams.join('&'));

        if (data?.results) {

          // Ensure the state is updated before proceeding
          if (user.isExternalUser || user.isInternalUser) {

            if (planMode === 'Trial' && data.results.cardTotalLimitAvailable === 0) {
              setActivateTopUpCreditModalState(true);
              return;

            } else if (data.results.limitExceeded) {

              setActivateTopUpCreditModalState(true);
              return;
            }
          }
        }
      } catch (error) {
        // Handle the error appropriately
        console.error('Error fetching user plan details:', error);
      }
    }

    if (filteredCrumbList?.length > 0) {
      dispatch(setCreateAudienceModalState(true));
    } else {
      dispatch(
          setMessage({
            type: "error",
            title: "Please create a crumb first",
          })
      );
      dispatch(setMessageSnackbarStateState(true));
    }
  };

  const handleCancelAcudience = () => {
    localStorage.removeItem("selectedSearchKeywords");
    dispatch(setDefaultSearchKeywords([]));
    dispatch(setPoiIdsList(''));
    dispatch(setBrandIdList(''));
    dispatch(setAudienceIdsList(''));
    //setIsPOIDetails(false);
    navigate('/');
  };

  const handleSearchFilterPlacesButton = () => {
    setFilterResultOpen(!filterResultOpen);
    setFilterMoreOptionOpen(false);
  }

  const handleSearchFilterMoreButton = () => {
    setFilterMoreOptionOpen(!filterMoreOptionOpen);
  }

  const handleFilters = (sliderValues, isSave, isNewFilters) => {
    if (isNewFilters) {
      mergeHandleApplyFilters(sliderValues, isSave);
    } else {
      handleApplyFilters(sliderValues, isSave);
    }
  };

  const [loading, setLoading] = useState(false);
  // const [estimateAudiences, setEstimateAudiences] = useState(null);
  const handleApplyFilters = (sliderValues, isSave) => {
    // const poiIds = searchParams.get('poiIds');
    // console.log("slider val", sliderValues)

    const applyFilterParams = JSON.parse(localStorage.getItem('applyFilterParams'));

    let isPOIDetails = 'isPOIDetailsId' in applyFilterParams ? applyFilterParams.isPOIDetailsId : false;

    if (user && selectedCountry && (poiIdsList || isPOIDetails)) {
      setLoading(true);
      // const dayPartingOptions = [1, 2, 3, 0];
      // const selectedDayParting = dayPartingOptions[sliderValues[3]];
      const queryParams = [
        `visitPeriod=${sliderValues[0]}`,
        `geoRadius=${sliderValues[1]}`,
        `weekParting=${sliderValues[2]}`,
        `dayParting=${sliderValues[3].toString()}`,
        // `dayParting=${dayParting.toString()}`,
        `userId=${user.id}`,
        `countryId=${selectedCountry.id}`,
        `rejectedPOIList=${poiRejectedIds}`
      ]

      if (isPOIDetails) {

        if (applyFilterParams.category !== null) {
          queryParams.push(`category=${applyFilterParams.category}`);
        }

        // if (applyFilterParams.city !== null) {
        //   queryParams.push(`city=${applyFilterParams.city}`);
        // }

        if (applyFilterParams.brandIdList !== null) {
          queryParams.push(`brandIdList=${applyFilterParams.brandIdList}`);
        }

        queryParams.push(`isPOIDetailsId=${isPOIDetails}`);

      }

      if (poiIdsList !== '') {
          queryParams.push(`poiId=${poiIdsList}`);
        }


      PoiService.applyFilter(queryParams.join('&'))
        .then((response) => {
          const resultData = response?.results;
          // console.log("applyFilter", resultData);
          if (resultData) {
            localStorage.setItem("audience", JSON.stringify(resultData));
            const applyFilterParams = {
              visitPeriod: resultData.visitPeriod,
              geoRadius: resultData.geoRadius,
              weekParting: resultData.weekParting,
              dayParting: resultData.dayParting,
              audiences: resultData.audience,
              audienceId: '',
              //city: resultData.city,
              category: resultData.category,
              isPOIDetailsId: resultData.isPOIDetailsId,
              brandIdList: resultData.brandIdList
            };

            localStorage.setItem('applyFilterParams', JSON.stringify(applyFilterParams));

            // if (response.results.audience < 3000) {
            //   {
            //     dispatch(setMessage({
            //       type: "error",
            //       title: "Low audiences",
            //     }))
            //   }
            //   { dispatch(setMessageSnackbarStateState(true)) }
            // }
            dispatch(setEstimateAudiences(resultData.audience));
            dispatch(setAudienceLimit(resultData.audienceLimit ? resultData.audienceLimit : 0));

            if (resultData.audience < resultData.audienceLimit) {
              isSave = false;
            }
          }
        })
        .catch(error => {
          isSave = false;
          // Handle any errors that occur during the request
          dispatch(
            setMessage({
              type: "error",
              title: error?.response?.data?.message,
            })
          );
          dispatch(setMessageSnackbarStateState(true));
          dispatch(setEstimateAudiences(0));
        })
        .finally(() => {
          setLoading(false); // set loading state to false after the API call completes
          if (isSave) {
            handleSaveAudience();
          }
        });
    }
  }

  const mergeHandleApplyFilters = (sliderValues, isSave) => {
    // const poiIds = searchParams.get('poiIds');
    // console.log("slider val", sliderValues)

    const applyFilterParams = JSON.parse(localStorage.getItem('applyFilterParams'));

    if (user && selectedCountry && (poiIdsListForFilter) || applyFilterParams.brandIdList !== null || applyFilterParams.userPoiList !== null) {
      setLoading(true);
      // const dayPartingOptions = [1, 2, 3, 0];
      // const selectedDayParting = dayPartingOptions[sliderValues[3]];
      const queryParams = [
        `visitPeriod=${sliderValues[0]}`,
        `geoRadius=${sliderValues[1]}`,
        `weekParting=${sliderValues[2]}`,
        `dayParting=${sliderValues[3].toString()}`,
        // `dayParting=${dayParting.toString()}`,
        `userId=${user.id}`,
        `countryId=${selectedCountry.id}`,
        `rejectedPOIList=${poiRejectedIds}`,
        `rejectedLocationList=${locationRejectedIds}`
      ]


      if (applyFilterParams.brandIdList !== null) {
        queryParams.push(`brandIdList=${applyFilterParams.brandIdList}`);
      }

      if (applyFilterParams.userPoiList !== null) {
        queryParams.push(`userPoiList=${applyFilterParams.userPoiList}`);
      }

      if (poiIdsListForFilter !== '') {
        queryParams.push(`poiId=${poiIdsListForFilter}`);
      }

      PoiService.mergeApplyFilter(queryParams.join('&'))
          .then((response) => {
            const resultData = response?.results;
            // console.log("applyFilter", resultData);
            if (resultData) {
              localStorage.setItem("audience", JSON.stringify(resultData));
              const applyFilterParams = {
                visitPeriod: resultData.visitPeriod,
                geoRadius: resultData.geoRadius,
                weekParting: resultData.weekParting,
                dayParting: resultData.dayParting,
                audiences: resultData.audience,
                audienceId: '',
                isPOIDetailsId: resultData.isPOIDetailsId,
                brandIdList: resultData.brandIdList
              };

              localStorage.setItem('applyFilterParams', JSON.stringify(applyFilterParams));

              // if (response.results.audience < 3000) {
              //   {
              //     dispatch(setMessage({
              //       type: "error",
              //       title: "Low audiences",
              //     }))
              //   }
              //   { dispatch(setMessageSnackbarStateState(true)) }
              // }
              dispatch(setEstimateAudiences(resultData.audience));
              dispatch(setAudienceLimit(resultData.audienceLimit ? resultData.audienceLimit : 0));

              if (resultData.audience < resultData.audienceLimit) {
                isSave = false;
              }
            }
          })
          .catch(error => {
            isSave = false;
            // Handle any errors that occur during the request
            dispatch(
                setMessage({
                  type: "error",
                  title: error?.response?.data?.message,
                })
            );
            dispatch(setMessageSnackbarStateState(true));
            dispatch(setEstimateAudiences(0));
          })
          .finally(() => {
            setLoading(false); // set loading state to false after the API call completes
            if (isSave) {
              handleSaveAudience();
            }
          });
     }
    }


  const handleDeletePlaceKeywords = (place) => {
    // console.log("handleDeletePlaceKeywords", place);
    // console.log("audienceIdsList", audienceIdsList.audienceList);
    // console.log("poiIdsList", poiIdsList);

    if (audienceIdsList && place?.name) {
      // console.log("audienceIdsList", audienceIdsList)
      setIsFilterLoading(true);
      setIsTotalPlacesLoading(true);
      dispatch(setPoiIdsList(''));
      const selectedSearchKeywords = localStorage.getItem("selectedSearchKeywords") ? JSON.parse(localStorage.getItem("selectedSearchKeywords")) : [];
      // console.log("selectedSearchKeywords", selectedSearchKeywords);
      const selectedSearchFilteredKeywords = selectedSearchKeywords.filter(s => s.name !== place.placeName);
      // const selectedAudienceIds = audienceIdsList.audienceList.split(',');
      // const selectedFilteredAudienceIds = selectedAudienceIds.filter(s => parseInt(s) !== place.placeName);
      // console.log("selectedSearchFilteredKeywords", selectedSearchFilteredKeywords);
      if (selectedSearchFilteredKeywords?.length) {
        localStorage.setItem("selectedSearchKeywords", JSON.stringify(selectedSearchFilteredKeywords));
        // console.log(selectedSearchFilteredKeywords)
        dispatch(setDefaultSearchKeywords(selectedSearchFilteredKeywords));
        // const filteredAudienceIds = selectedFilteredAudienceIds.join(',');
        // console.log(filteredAudienceIds)

        let poiIds = [];
        let audienceIds = [];

        selectedSearchFilteredKeywords.forEach(item => {
          if (item.type === 'poi' || item.type === 'user_poi') {
            poiIds.push(item.id);
          } else {
            audienceIds.push(item.id);
          }
        });

        const mergeList = {
          poiList: poiIds,
          audienceList: audienceIds
        };

        // console.log("poiList", poiIds, "audienceList", audienceIds)
        if (poiIds || audienceIds) {
          dispatch(setAudienceIdsList(mergeList));
        }
        // dispatch(setAudienceIdsList(filteredAudienceIds));
        // loadAudiencByIds(filteredAudienceIds);
      } else {
        localStorage.removeItem("selectedSearchKeywords");
        dispatch(setDefaultSearchKeywords([]));
        dispatch(setAudienceIdsList(''));
        navigate('/');
      }
    }

    if (poiIdsList) {
      // console.log("poiIdsList", poiIdsList)
      setIsFilterLoading(true);
      setIsTotalPlacesLoading(true);
      localStorage.removeItem("selectedSearchKeywords");
      dispatch(setDefaultSearchKeywords([]));
      dispatch(setAudienceIdsList(''));
      const selectedPoiIds = poiIdsList.split(',');
      const selectedFilteredPoiIds = selectedPoiIds.filter(s => parseInt(s) !== place.id);
      // console.log("selectedSearchFilteredKeywords", selectedFilteredPoiIds);
      if (selectedFilteredPoiIds?.length) {
        // navigate(`/search?poiIds=${selectedFilteredPoiIds.join(',')}`);
        const filteredPoiIds = selectedFilteredPoiIds.join(',');
        dispatch(setPoiIdsList(filteredPoiIds));
        // loadPoiByIds(filteredPoiIds);
      } else {
        dispatch(setPoiIdsList(''));
        navigate('/');
      }
    }

    if (brandIdList) {
      // console.log("poiIdsList", poiIdsList)
      setIsFilterLoading(true);
      setIsTotalPlacesLoading(true);
      localStorage.removeItem("selectedSearchKeywords");
      dispatch(setDefaultSearchKeywords([]));
      dispatch(setAudienceIdsList(''));
      const selectedBrandIds = brandIdList.split(',');
      const selectedFilteredBrandIds = selectedBrandIds.filter(s => parseInt(s) !== place.id);
      // console.log("selectedSearchFilteredKeywords", selectedFilteredPoiIds);
      if (selectedFilteredBrandIds?.length) {
        // navigate(`/search?poiIds=${selectedFilteredPoiIds.join(',')}`);
        const filteredBrandIds = selectedFilteredBrandIds.join(',');
        dispatch(setBrandIdList(filteredBrandIds));
        // loadPoiByIds(filteredPoiIds);
      } else {
        dispatch(setBrandIdList(''));
        navigate('/');
      }
    }
  }

  const handleCheckboxChange = (sectionIndex, value) => {
    const updatedDayParting = dayParting.includes(value) ? dayParting.filter((item) => item !== value) : [...dayParting, value];
    setDayParting(updatedDayParting.sort((a, b) => a - b));

    const sliderValuesDummy = [...sliderValues];
    sliderValuesDummy[sectionIndex] = [updatedDayParting];
    dispatch(setSliderValues(sliderValuesDummy));

    const updatedFilterMoreGroups = filterMoreGroups.map((group) => {
      if (group.title === "Day Parting") {
        return {
          ...group,
          options: group.options.map((option, optionIndex) => ({
            ...option,
            isDefault: updatedDayParting.includes(optionIndex).toString(),
          })),
        };
      } else {
        return group; // Keep other groups unchanged
      }
    });

    dispatch(setFilterMoreGroups(updatedFilterMoreGroups));
  };

  const handleRadioChange = (sectionIndex, value) => {
    const sliderValuesDummy = [...sliderValues];
    sliderValuesDummy[sectionIndex] = value;
    dispatch(setSliderValues(sliderValuesDummy));

    const updatedFilterMoreGroups = filterMoreGroups.map((group) => {
      if (group.title === "Week Parting") {
        return {
          ...group,
          options: group.options.map((option) => ({
            ...option,
            isDefault: option.value === value ? 'true' : 'false',
          })),
        };
      } else {
        return group; // Keep other groups unchanged
      }
    });

    dispatch(setFilterMoreGroups(updatedFilterMoreGroups));
  };

  return (<>
    <Box className={`${styles['theme-' + currentTheme]} `}>
      {poiDetails &&
        <Box className={`${styles.SearchFilter ?? ''}`} sx={{ position: 'relative' }}>

          <Button className={`${styles.SearchFilterPlacesButton ?? ''}`} sx={{ p: 2.5, width: '100%', minWidth: 'initial', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} onClick={handleSearchFilterPlacesButton}>
            <Box className={`${styles.SearchFilterPlacesButtonContent ?? ''}`} sx={{ display: 'flex', width: 'calc(100% - 40px)' }}>
              <Box className={`${styles.SearchFilterPlacesButtonIcon ?? ''}`} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 32, height: 32, mr: 1.2, borderRadius: '50%', overflow: 'hidden', backgroundColor: '#FFF0E6' }}>
                <Image src={locationCircle} style={{ width: 18, height: 18 }} />
              </Box>
              <Box className={`${styles.SearchFilterPlacesButtonTexts ?? ''}`} sx={{ display: 'flex', flexDirection: 'column', width: 'calc(100% - 40px)' }}>
                <Box className={`${styles.SearchFilterPlacesButtonTitle ?? ''}`} sx={{ display: 'flex', fontSize: 10, fontWeight: '400', lineHeight: '13px', mb: 0.5, textTransform: 'capitalize' }}>Places</Box>
                {isTotalPlacesLoading ?
                  <LinearProgress />
                  :
                  <Box className={`${styles.SearchFilterPlacesButtonValue ?? ''} ff-tomorrow`} sx={{ display: 'flex', fontSize: 14, fontWeight: '500', lineHeight: '17px' }}>{totalPlaces}</Box>
                }
              </Box>
            </Box>
            <Box className={`${styles.SearchFilterPlacesArrow ?? ''}`} sx={{ display: 'flex' }}>
              {filterResultOpen &&
                <KeyboardArrowUpOutlinedIcon sx={{ width: 25, height: 25 }} />
              }
              {!filterResultOpen &&
                <KeyboardArrowDownIcon sx={{ width: 25, height: 25 }} />
              }
            </Box>
          </Button>

          <Box className={`${styles.SearchFilterWrapperArea ?? ''}`} sx={{ position: 'relative', overflow: 'hidden', overflowY: 'auto' }}>
            {isFilterLoading &&
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', position: 'absolute', left: 0, top: 0, zIndex: 1 }}></Box>
            }

            <Collapse in={filterResultOpen}>
              <Box className={`${styles.SearchFilterResultListArea ?? ''}`} sx={{ position: 'relative' }}>
                {placesList?.length > 0 &&
                  <Box className={`${styles.SearchFilterResultList ?? ''}`} sx={{ position: 'relative', p: 2.5 }}>
                    <Box className={`${styles.SearchFilterResultListTitle ?? ''}`} sx={{ display: 'flex', fontSize: 10, fontWeight: '400', lineHeight: '13px', textTransform: 'capitalize', mb: 1.5 }}>Places</Box>
                    {placesList.map((place, index) => {
                      return (
                        <Box className={`${styles.SearchFilterResult ?? ''}`} sx={{ width: '100%', minWidth: 'initial', display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 2 }} key={index}>
                          <Box className={`${styles.SearchFilterResultContent ?? ''}`} sx={{ display: 'flex', width: 'calc(100% - 40px)' }}>
                            <Box className={`${styles.SearchFilterResultIcon ?? ''}`} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 32, height: 32, mr: 1.2, borderRadius: '50%', overflow: 'hidden' }}>
                              {/* <Image src={locationCircle} style={{ width: 18, height: 18 }} /> */}
                              <Location style={{ color: colorIndex[index % colorIndex.length] }} />
                            </Box>
                            <Box className={`${styles.SearchFilterResultTexts ?? ''}`} sx={{ display: 'flex', flexDirection: 'column', width: 'calc(100% - 40px)' }}>
                              <Box className={`${styles.SearchFilterResultTitle ?? ''}`} sx={{ display: 'flex', fontSize: 10, fontWeight: '400', lineHeight: '13px', mb: 0.5, textTransform: 'capitalize' }}>{place.placeName}</Box>
                              <Box className={`${styles.SearchFilterResultValue ?? ''} ff-tomorrow`} sx={{ display: 'flex', fontSize: 14, fontWeight: '500', lineHeight: '17px', color: colorIndex[index % colorIndex.length] }}>{place.count} Places</Box>
                            </Box>
                          </Box>
                          <Button className={`${styles.SearchResultDeleteButton ?? ''}`} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 20, height: 20, minWidth: 'initial', borderRadius: '50%' }} onClick={() => handleDeletePlaceKeywords(place)}>
                            <CloseIcon sx={{ width: 12, height: 12 }} />
                          </Button>
                        </Box>
                      )
                    })}
                  </Box>
                }

                <Box className={`${styles.SearchAudienceResultList ?? ''}`} sx={{ position: 'relative', px: 2.5, py: 1 }}>

                  <Box className={`${styles.SearchAudienceResult ?? ''}`} sx={{ width: '100%', minWidth: 'initial', display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 2 }}>
                    <Box className={`${styles.SearchAudienceResultContent ?? ''}`} sx={{ display: 'flex', width: 'calc(100% - 40px)' }}>
                      <Box className={`${styles.SearchAudienceResultIcon ?? ''}`} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 32, height: 32, mr: 1.2, borderRadius: '50%', overflow: 'hidden', backgroundColor: '#E5F7FF' }}>
                        <Image src={glasses} style={{ width: 18, height: 18 }} />
                      </Box>
                      <Box className={`${styles.SearchAudienceResultTexts ?? ''}`} sx={{ display: 'flex', flexDirection: 'column', width: 'calc(100% - 40px)' }}>
                        <Box className={`${styles.SearchAudienceResultTitle ?? ''}`} sx={{ display: 'flex', fontSize: 10, fontWeight: '400', lineHeight: '13px', mb: 0.5, textTransform: 'capitalize' }}>Est Addressable Audience {sliderValues[0] ? -(sliderValues[0]) + 'd' : 0}</Box>
                        {loading ? (
                          <LinearProgress />
                        ) : (
                          <Box className={`${styles.SearchFilterResultValue ?? ''} ff-tomorrow`} sx={{ display: 'flex', fontSize: 14, fontWeight: '500', lineHeight: '17px' }}>
                            {estimateAudiences < audienceLimit ? (
                              'No Potential Audience!'
                            ) : (
                              estimateAudiences.toLocaleString()
                            )}
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>

                </Box>


                <Box className={`${styles.SearchFilterActions ?? ''}`} sx={{ position: 'relative', display: 'flex' }}>
                  <Button className={`${styles.SearchFilterAudienceButton ?? ''}`} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 48, height: 48, minWidth: 'initial', borderRadius: 0 }} onClick={handleUploadAudience}>
                    <Export />
                  </Button>

                  <Button className={`${styles.SearchFilterMoreButton ?? ''}`} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 48, height: 48, minWidth: 'initial', borderRadius: 0 }} onClick={handleSearchFilterMoreButton}>
                    <Setting4 />
                  </Button>
                  {addCard &&
                    <Button className={`${styles.SearchFilterSaveButton ?? ''}`} sx={{ display: 'flex', flex: '1', alignItems: 'center', justifyContent: 'center', height: 48, minWidth: 'initial', textTransform: 'capitalize', borderRadius: 0, filter: estimateAudiences < 1 && 'grayscale(100%)', }} onClick={() => handleFilters(sliderValues, true, isMergeFilter)} disabled={estimateAudiences < 1}>Save</Button>
                  }
                  <Button sx={{ display: 'flex', flex: '1', alignItems: 'center', justifyContent: 'center', height: 48, minWidth: 'initial', textTransform: 'capitalize', borderRadius: 0, background: "#F1F1F1", color: "blue", '&:hover': { background: "white !important" } }} onClick={() => handleCancelAcudience()}>Cancel</Button>
                </Box>

              </Box>
            </Collapse>

            <Collapse in={filterMoreOptionOpen}>
              <Box className={`${styles.SearchFilterMoreArea ?? ''}`} sx={{ position: 'relative', }}>
                <Box className={`${styles.SearchFilterGroupArea ?? ''}`} sx={{ position: 'relative', py: 1 }}>
                  {filterMoreGroups?.length > 0 && filterMoreGroups.map((filter, index) => {
                    return (
                      <Box className={`${styles.SearchFilterGroup ?? ''}`} sx={{ position: 'relative', px: 2.5, py: 2 }} key={index}>
                        <Box className={`${styles.SearchFilterGroupTitle ?? ''}`} sx={{ position: 'relative', fontSize: 16, fontWeight: '600', lineHeight: '20px', mb: 2 }}>{filter.title}</Box>
                        <Box className={`${styles.SearchFilterGroupSlider ?? ''}`} sx={{ position: 'relative', px: 1 }}>
                          {filter.title !== "Day Parting" && filter.title !== "Week Parting" ? (
                            <PrettoSlider
                              aria-label="pretto slider"
                              defaultValue={defaultSliderValues[index]}
                              marks={filter.options}
                              step={null}
                              valueLabelDisplay="auto"
                              min={filter.options[0].value}
                              max={filter.options[filter.options.length - 1].value
                              }
                              value={defaultSliderValues[index]}
                              onChange={
                                (event, newValue) => {
                                  const sliderValuesDummy = [...sliderValues];
                                  sliderValuesDummy[index] = newValue;
                                  dispatch(setSliderValues(sliderValuesDummy));
                                  dispatch(setDefaultSliderValues(sliderValuesDummy));
                                }}
                            />
                          ) : filter.title === "Day Parting" ? (
                            <Box display="flex" justifySelf="center">
                              {filter.options.map((option, optionIndex) => (
                                <Box key={option.value} mr={3.5}>
                                  <Checkbox
                                    icon={<Brightness1OutlinedIcon sx={{ color: currentTheme === 'light' ? '#010412' : '#FFF' }} />}
                                    checkedIcon={<Brightness1Icon color="warning" />}
                                    checked={option.isDefault === 'true'}
                                    onChange={() => handleCheckboxChange(index, optionIndex)}
                                  />
                                  <Box
                                    mr={3.5}
                                    sx={{
                                      width: '10px',
                                      height: '10px',
                                      borderRadius: '20px',
                                      transform: 'translate(-5px, -50%)',
                                      color: '#6F7288',
                                      fontSize: '10px',
                                      alignContent: 'center',
                                      marginLeft: '8px'
                                    }}
                                  >
                                    {option.label}
                                  </Box>
                                </Box>
                              ))}
                            </Box>
                          ) : (
                            <Box display="flex" justifySelf="center">
                              {filter.options.map((option, optionIndex) => (
                                <Box key={option.value} mr={3.5}>
                                  <Radio
                                    icon={<Brightness1OutlinedIcon sx={{ color: currentTheme === 'light' ? '#010412' : '#FFF' }} />}
                                    checkedIcon={<Brightness1Icon color="warning" />}
                                    checked={option.isDefault === 'true'}
                                    // value={option.value}
                                    onChange={() => handleRadioChange(index, optionIndex)}
                                    name="radio-buttons"
                                  />
                                  <Box
                                    mr={3.5}
                                    pl={1}
                                    sx={{
                                      width: '10px',
                                      height: '10px',
                                      borderRadius: '20px',
                                      transform: 'translate(-5px, -50%)',
                                      color: '#6F7288',
                                      fontSize: '10px',
                                      alignContent: 'center',
                                      marginLeft: '8px'
                                    }}
                                  >
                                    {option.label}
                                  </Box>
                                </Box>
                              ))}
                            </Box>
                          )}
                        </Box>
                      </Box>
                    )
                  })}
                </Box>

                <Button className={`${styles.SearchFilterApplyButton ?? ''}`} sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', height: 48, minWidth: 'initial', textTransform: 'capitalize', borderRadius: 0 }} onClick={() => handleFilters(sliderValues, false, isMergeFilter)}>Apply Filters</Button>
              </Box>
            </Collapse>

          </Box>

        </Box>
      }
    </Box>

        {activateTopUpCreditModalState &&
            <ActivateTopUpCreditModal
            activateTopUpCreditModalState={activateTopUpCreditModalState}
            setActivateTopUpCreditModalState={setActivateTopUpCreditModalState}
        />
        }
      </>
  );
}

export default SearchFilter;