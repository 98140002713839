import React, { useState, useEffect } from "react";
import {Box, Button, List, ListItem, CircularProgress, Radio, InputLabel, Divider} from "@mui/material";
import Image from "mui-image";
import CloseIcon from '@mui/icons-material/Close';
import {useSelector, useDispatch, connect} from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import { useWindowWidth } from "../../../../utils/browser";
import {setMessageSnackbarStateState, setTopUpDetailsState} from "../../../../redux/slices/common";
import styles from './TopupDetails.module.scss';
import {loadedLoggedIn, setUser} from "../../../../redux/slices/auth";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import mail from "../../../../assets/images/icons/svg/sms.svg";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import StripeService from "../../../../services/api/stripe.service";
import {setMessage} from "../../../../redux/slices/message";


const TopUpDetails = ({ isCollapsed, handleCollapsed }) => {

    const browserWidth = useWindowWidth();
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { topUpDetailsState } = useSelector((state) => state.common);
    const { selectedCountry } = useSelector((state) => state.auth);
    const { user } = useSelector((state) => state.auth.auth);
    const { currentTheme } = useSelector((state) => state.theme);
    const [ creditAmount, setCreditAmount] = useState(0);
    const [isCardChecked, setIsCardChecked] = useState(false);
    const [isAudienceChecked, setIsAudienceChecked] = useState(false);
    const userFromLocalStorage = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        let mounted = true;
        // if (mounted && params) {
        //
        //     const userId = user?.id || userFromLocalStorage?.id;
        //
        //     const queryCalcParams = [
        //         `userId=${userId}`,
        //         `countryId=${selectedCountry.id}`,
        //         `connectorsCount=${1}`
        //     ];
        //
        //         queryCalcParams.push(`audienceCount=${1}`);
        //     StripeService.getAmount(queryCalcParams.join('&')).then((data) => {
        //
        //             if (data?.results) {
        //                 setA
        //                 setAmountDetails(data.results);
        //             }
        //             return Promise.resolve();
        //         }, (error) => {
        //             return Promise.reject();
        //         }
        //     )
        // }

        return () => {
            mounted = false;
            localStorage.removeItem('applyFilterParams');
        }
    }, [selectedCountry, params, dispatch]);


    const onSubmit = data => {
        console.log(JSON.stringify(data, null, 2));
    };

    const handleOpen = () => {
        dispatch(setTopUpDetailsState(true));
    };

    const handleClose = () => {
        dispatch(setTopUpDetailsState(false));
        handleCollapsed();
    };

    const handleSubmit = () => {

        if(creditAmount < 1) {
            dispatch(setMessage({
                type: "error",
                title: "The minimum amount should be $1",
            }));
            dispatch(setMessageSnackbarStateState(true));
            return;
        }

        navigate('/check-out', { state: { creditAmount } });
    }


    const handleAudienceCountChange = async (event) => {
        const count = Number(event.target.value); // Ensure count is a number

        if (!isNaN(count)) {

            if (count > 99999) {
                // Dispatch an error if count is more than 10,000
                dispatch(setMessage({
                    type: "error",
                    title: "For larger amounts, please contact our Sales team for assistance.",
                }));
                dispatch(setMessageSnackbarStateState(true));
                return;
            }

            setCreditAmount(count);
        }
    };

    return (<>
            <SwipeableDrawer
                anchor={"right"}
                open={topUpDetailsState}
                onClose={handleClose}
                onOpen={handleOpen}
                PaperProps={{
                    sx: {
                        width: "30%",
                        borderLeft: "1px solid #212330",
                    }
                }}
                sx={{
                    backdropFilter: "blur(2px)",
                }}
            >

                <Box
                    style={{
                        paddingTop: '50px',
                        paddingLeft: '50px',
                        paddingRight: '30px',
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100vh',
                        justifyContent: 'space-between' // This pushes the button to the end
                    }}
                >

            <Grid container rowSpacing={0}>
                <Grid item style={{paddingTop: '6px'}} xs={12}>
                    <Typography
                                style={{ color: currentTheme === 'dark' ? '#ffffff' : '#202020', fontSize: '32px'}}
                                variant="contained">
                        Top Up your account
                    </Typography>
                </Grid>
                <Grid item style={{paddingTop: '6px'}} xs={12}>
                    <Typography
                        style={{ color: currentTheme === 'dark' ? '#9A9CA7' : '#202020', fontSize: '18px'}}
                        variant="contained">
                        Enter the amount below
                    </Typography>
                </Grid>
                    <Grid item container style={{ paddingTop: '30px', paddingLeft: '15px' }}>
                        <Grid xs={12}>
                            <FormControlLabel
                                style={{ fontWeight: '900' }}
                                control={ <Radio style={{ color: currentTheme === 'dark' ? '#ffffff' : '#202020' }} checked /> }
                                label={
                                    <Typography style={{ fontSize: '20px', fontWeight: '550' }}>
                                        Credit Purchase
                                    </Typography>
                                }
                            />
                        </Grid>

                        <Grid xs={10} ml={4} style={{ display: 'flex', alignItems: "center", gap: '10px'}}>
                            <TextField variant="outlined"  value={creditAmount} onChange={handleAudienceCountChange} fullWidth placeholder='Audience Count' inputProps={{ style: { color: currentTheme === 'dark' ? '#ffffff' : undefined , height: '10px'  } }} sx={{
                                width: '300px',
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: currentTheme === 'dark' ? '#1565c0' : '#202020', // Default MUI border color
                                    },
                                    '&:hover fieldset': {
                                        borderColor: currentTheme === 'dark' ? '#1e88e5' : '#303030', // Hover border color
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: currentTheme === 'dark' ? '#42a5f5' : '#505050', // Focused border color
                                    },
                                    '& .MuiOutlinedInput-input': {
                                        color: currentTheme === 'dark' ? '#ffffff' : '#110202', // Text color
                                    },
                                    '&.Mui-disabled fieldset': {
                                        color: currentTheme === 'dark' ? '#888888' : '#999999', // Disabled text color based on theme
                                        borderColor: currentTheme === 'dark' ? '#888888' : '#999999', // Disabled border color based on theme
                                    },
                                },
                            }}/>
                            <InputLabel style={{ color: currentTheme === 'dark' ? '#ffffff' : '#202020', width: '200px', paddingLeft: '25px' }}>  { creditAmount.toLocaleString() } USD </InputLabel>
                        </Grid>
                    </Grid>
                <Grid xs={12} style={{ paddingTop: '6px'}}>
                  <label variant="standard" placeholder='Custom Amount' inputProps={{ style: { color: currentTheme === 'dark' ? '#ffffff' : undefined   } }} style={{ width: "300px", height: "45px" ,paddingTop: '10px', paddingLeft: '15px'}}/>
                </Grid>
                <Divider className={currentTheme === 'dark' ? styles['custom-divider'] : styles['custom-white']} sx={{my: 2, borderColor: currentTheme === 'dark' ? '#5a1d38' : 'transparent'}}/>
                <Grid xs={6} style={{paddingTop : '8px', paddingLeft : '45px'}}>
                    <InputLabel style={{ color: currentTheme === 'dark' ? '#ffffff' : '#202020' }}> Total </InputLabel>
                </Grid>
                <Grid xs={6} style={{ fontFamily: 'Manrope, Arial, sans-serif' , paddingTop : '8px', paddingLeft : '60px'}}>
                    <InputLabel style={{ color: '#3955BF', fontWeight: '700' }}>  { creditAmount.toLocaleString() } USD </InputLabel>
                </Grid>
            </Grid>

            <Box mt={3} style={{ paddingBottom: '25px'}}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    fullWidth
                >
                    Pay now
                </Button>
            </Box>
        </Box>
            </SwipeableDrawer>
        </>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.auth.user,
    currentTheme: state.theme.currentTheme
});

export default connect(mapStateToProps)(TopUpDetails);
