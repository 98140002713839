import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import StripeService from "../../services/api/stripe.service";
import {Box} from "@mui/system";
import Image from "mui-image";
import checkCircle from "../../assets/images/check-circle-black.png";

function ReturnForm() {
    const [status, setStatus] = useState(null);
    const [customerEmail, setCustomerEmail] = useState('');
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.auth.auth);
    const userFromLocalStorage = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get('session_id');
        const uniqueId = urlParams.get('unique_transaction_id');
        const id = urlParams.get('userId');

        let userId = user?.id || userFromLocalStorage?.id;

        userId = userId ?? id;

        const queryCalcParams = [
            `userId=${userId}`,
            `session_id=${sessionId}`,
            `unique_id=${uniqueId}`,
        ];

        StripeService.getSessionReturn(queryCalcParams.join('&'))
            .then((data) => {
                setStatus(data.results.status);
                setCustomerEmail(data.results.customer_email);
            })
            .catch((error) => {
                console.error("Error fetching session return data:", error);
            });

    }, []);

    useEffect(() => {
        let timer;
        if (status === 'complete') {
            timer = setTimeout(() => {
                navigate('/user-profile');
            }, 6500);
        }

        return () => clearTimeout(timer);
    }, [status, navigate]);

    if (status === 'open') {
        navigate('/checkout');
        return null; // Ensure nothing is rendered before navigation
    }

    if (status === 'complete') {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'white' }}>
                <section id="success" style={{ textAlign: 'center' }}>
                    <Box sx={{ width: 100, height: 100, marginBottom: '100px' , marginLeft: '240px'}}>
                        <Image src={checkCircle} alt='logo' />
                    </Box>
                    <p style={{ whiteSpace: 'pre-wrap', lineHeight: '1.5' }}>
                        We appreciate your business! A confirmation email will be sent to {customerEmail}.</p>
                    <p style={{ whiteSpace: 'pre-wrap', lineHeight: '1.5' }}>
                        If you have any questions, please email <a href="mailto:cr@memob.com">cr@memob.com</a>   Please wait.
                    </p>
                </section>
            </div>
        );
    }

    return <div style={{ backgroundColor: 'white', height: '100vh' }}></div>;
}

export default ReturnForm;
