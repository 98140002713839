import React from 'react';
import { Box } from '@mui/material';
import MenuSideBar from '../../components/common/sidebar/menu-sidebar/MenuSideBar';
import SearchAutoComplete from '../../components/common/search/SearchAutoComplete';
import HomepageDetails from '../../components/common/details/home-page-details/HomepageDetails';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ClusterdMap from '../../components/common/map/ClusterdMap';
import AudienceSlider from '../../components/common/slider/audience-slider/AudienceSlider';
import AudienceService from '../../services/api/audience.service';
import { setMessage } from '../../redux/slices/message';
import {setCreateAudienceModalState, setMessageSnackbarStateState} from '../../redux/slices/common';
import { errorMessage } from '../../config/config';
import { setMapPoints } from '../../redux/slices/map';
import { setCheckedSystem, setCheckedUser, setPoiIdsList } from '../../redux/slices/audience';
import StripeService from "../../services/api/stripe.service";
import ActivateTopUpCreditModal from "../../components/common/modal/activate-top-credit-modal/ActivateTopUpCreditModal";

const FavouriteCardsPage = () => {

  const { user } = useSelector((state) => state.auth.auth);
  const { selectedCountry } = useSelector((state) => state.auth);
  const { audienceHistoryChange } = useSelector((state) => state.audience);
  const [audienceList, setAudienceList] = useState(null);
  const dispatch = useDispatch();
  const userFromLocalStorage = JSON.parse(localStorage.getItem('user'));
  const [activateTopUpCreditModalState, setActivateTopUpCreditModalState] = useState(false);
  const planMode = user?.planMode || userFromLocalStorage?.planMode;

  useEffect(() => {
    let mounted = true;
    dispatch(setMapPoints([]));
    dispatch(setPoiIdsList(''));
    dispatch(setCheckedUser([]));
    dispatch(setCheckedSystem([]));
    // console.log("audienceHistoryChange", audienceHistoryChange);
    if (mounted && user && selectedCountry) {
      const queryParams = [
        `userId=${user.id}`,
        `countryId=${selectedCountry.id}`,
      ];

      // fetch audiences
      AudienceService.getAudience(queryParams.join('&'))
        .then((data) => {
          console.log('from favourite page');
          const audienceList = data.results.reverse();
          const audienceFilteredList = audienceList.filter(x => (x.isLike === true));
          // console.log("audienceFilteredList", audienceFilteredList);
          if (audienceFilteredList.length > 0) {
            setAudienceList(audienceFilteredList);
            localStorage.setItem("AudienceList", JSON.stringify(audienceFilteredList));
          } else {
            setAudienceList([]);
            localStorage.setItem("AudienceList", JSON.stringify([]));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    return () => {
      mounted = false;
    }
  }, [user, selectedCountry, audienceHistoryChange]);

  // const noAudienceList = () => {
  //   setAudienceList([]);
  //   localStorage.setItem("AudienceList", JSON.stringify([]));
  //   dispatch(setMessage({
  //     type: "error",
  //     // title: data?.message ? data.message : "Audience restored successfully",
  //     title: "No Favorite Card",
  //   }));
  //   dispatch(setMessageSnackbarStateState(true));
  // }

  const handleDeactivateAudience = (audience) => {
    const queryParams = [
      `status=Deactivated`
    ]
    AudienceService.deactivateAudience(audience.id, queryParams.join('&')).then(
      (data) => {
        if (data) {
          const audienceDummy = audienceList.map(item => {
            if (item.id === audience.id) {
              return {
                ...item,
                isActive: 'Deactivated',
              };
            } else {
              return item
            }
          });
          // console.log("audienceDummy", audienceDummy);
          setAudienceList(audienceDummy);
          dispatch(setMessage({
            type: "warning",
            // title: data?.message ? data.message : "Audience restored successfully",
            title: "Your Card has been deactivated",
          }));
          dispatch(setMessageSnackbarStateState(true));
          // window.location.reload();
        }
      },
      (error) => {
        console.log(error);
        dispatch(setMessage({
          type: "error",
          title: errorMessage,
        }));
        dispatch(setMessageSnackbarStateState(true));
        return Promise.reject();
      }
    )
  }

  const handleActivateAudience = (audience) => {
    const queryParams = [
      `status=Activated`
    ]
    AudienceService.activateAudience(audience.id, queryParams.join('&')).then(
      (data) => {
        if (data) {
          const audienceDummy = audienceList.map(item => {
            if (item.id === audience.id) {
              return {
                ...item,
                isActive: 'Activated',
              };
            } else {
              return item
            }
          });
          // console.log("audienceDummy", audienceDummy);
          setAudienceList(audienceDummy);
          dispatch(setMessage({
            type: "success",
            // title: data?.message ? data.message : "Audience restored successfully",
            title: "Your Card has been Activated",
          }));
          dispatch(setMessageSnackbarStateState(true));
          // window.location.reload();
        }
      },
      (error) => {
        console.log(error);
        dispatch(setMessage({
          type: "error",
          title: errorMessage,
        }));  // const queryParams = [
        //   `userId=${user.id}`,
        //   `status=true`
        // ];
        dispatch(setMessageSnackbarStateState(true));
        return Promise.reject();
      }
    )
  }

  const handleDeleteAudience = (audience) => {
    const queryParams = [
      `status=Deleted`
    ]
    AudienceService.deleteAudience(audience.id, queryParams.join('&')).then(
      (data) => {
        // console.log("data", data);
        if (data) {
          if (data.results) {
            // console.log("audienceList", audienceList);
            let audienceDummy = audienceList.filter(a => a.id !== audience.id);
            // console.log("audienceDummy", audienceDummy);
            setAudienceList(audienceDummy);
            dispatch(setMessage({
              type: "error",
              title: "Your Card has been deleted",
            }));
            dispatch(setMessageSnackbarStateState(true));
          }
        } else {
          window.location.reload();
          dispatch(setMessage({
            type: "error",
            title: errorMessage,
          }));
          dispatch(setMessageSnackbarStateState(true));
        }
        return Promise.resolve();
      },
      (error) => {
        console.log(error);
        dispatch(setMessage({
          type: "error",
          title: errorMessage,
        }));
        dispatch(setMessageSnackbarStateState(true));
        return Promise.reject();
      }
    )
  }

  const handleAudienceRefresh = async (audience) => {

    if (user.isExternalUser || user.isInternalUser) {

    try {
      const queryCalcParams = [
        `userId=${user.id}`,
        `countryId=${selectedCountry.id}`,
      ];


      const data = await StripeService.getUserPlanDetails(queryCalcParams.join('&'));

      if (data?.results) {

        // Ensure the state is updated before proceeding
        if (planMode === 'Trial' && data.results.cardTotalLimitAvailable === 0) {

          setActivateTopUpCreditModalState(true);
          return;

        } else if (data.results.limitExceeded) {

          setActivateTopUpCreditModalState(true);
          return;
        }
      }
    } catch (error) {
      // Handle the error appropriately
      console.error('Error fetching user plan details:', error);
      // Optionally, you can set an error message state or show a notification
    }
  }

    const queryParams = [
      `audienceId=${audience.id}`,
      `userId=${user.id}`
    ];
    // setIsSubmitting(true);
    AudienceService.refreshAudience(queryParams.join('&')).then(
        (data) => {
          if (data) {
            const audienceDummy = audienceList.map(item => {
              if (item.id === audience.id) {
                return {
                  ...item,
                  action: data.action,
                  isUploaded: null
                };
              } else {
                return item
              }
            });
            // console.log("audienceDummy", audienceDummy);
            setAudienceList(audienceDummy);
            dispatch(setMessage({
              type: "success",
              // title: data?.message ? data.message : "Audience restored successfully",
              title: "Started Refreshing",
            }));
            dispatch(setMessageSnackbarStateState(true));
            // window.location.reload();
          }
        },
        (error) => {
          console.log(error);
          dispatch(setMessage({
            type: "error",
            title: error.response.data.message,
          }));
          dispatch(setMessageSnackbarStateState(true));
          return Promise.reject();
        }
    );
  };

  const handleAudienceDelete = (audienceId) => {
    const queryParams = [
      `status=Deleted`
    ];
    AudienceService.deleteAudience(audienceId, queryParams.join('&')).then(
      (data) => {
        if (data) {
          dispatch(setMessage({
            type: "success",
            // title: data?.message ? data.message : "Audience restored successfully",
            title: "Audience Deleted",
          }));
          dispatch(setMessageSnackbarStateState(true));
        }
      });
  };

  const updateAudiences = (audienceId) => {
    const audienceDummy = audienceList.filter(a => a.id !== audienceId);
    // console.log("audienceDummy", audienceDummy);
    setAudienceList(audienceDummy);
  };

  // const handleAudienceRename = (audience, rename, category, setRenameAudienceModalState) => {
  //   console.log(category)
  //   const queryParams = [
  //     `id=${audience.id}`
  //   ]
  //   const formData = {
  //     name: rename.trim(),
  //     categoryList: category.map(item => item.id),
  //     userId: user.id,
  //     countryId: selectedCountry.id,
  //   };
  //   // setIsSubmitting(true);
  //   AudienceService.renameAudience(queryParams.join('&'), formData).then(
  //     (data) => {
  //       if (data?.results) {
  //         dispatch(setMessage({
  //           type: "success",
  //           title: data?.message || "Audience data saved successfully",
  //         }));
  //         window.location.reload();
  //       }
  //       return Promise.resolve();
  //     },
  //     (error) => {
  //       dispatch(setMessage({
  //         type: "error",
  //         title: error?.response?.data?.message || "Audience Name is already taken!",
  //       }));
  //       dispatch(setMessageSnackbarStateState(true));
  //       dispatch(setRenameAudienceModalState(true));
  //       // setIsSubmitting(false);
  //       return Promise.reject();
  //     }
  //   )
  // };

  const handleAudienceRename = (audience, audienceId, rename, category, setRenameAudienceModalState) => {
    console.log(rename)
    console.log(category)
    const queryParams = [
      `id=${audience.id}`
    ]
    const formData = {
      name: rename.trim(),
      categoryList: category.map(item => item.id),
      userId: user.id,
      countryId: selectedCountry.id,
    };
    // setIsSubmitting(true);
    AudienceService.renameAudience(queryParams.join('&'), formData).then(
      (data) => {
        if (data?.results) {
          dispatch(setMessage({
            type: "success",
            title: data?.message || "Audience data saved successfully",
          }));
          dispatch(setMessageSnackbarStateState(true));
        }
        dispatch(setRenameAudienceModalState(false));
        return Promise.resolve();
      },
      (error) => {
        dispatch(setMessage({
          type: "error",
          title: error?.response?.data?.message || "Audience Name is already taken!",
        }));
        dispatch(setMessageSnackbarStateState(true));
        dispatch(setRenameAudienceModalState(true));
        // setIsSubmitting(false);
        return Promise.reject();
      }
    )
  };

  return (<>
    <Box className='HomepageArea' sx={{ display: 'flex' }}>
      <Box className="MapArea" sx={{ overflowX: 'hidden', height: '100vh', position: 'fixed', top: 0 }}>
        <ClusterdMap pointerState={true} />
        <Box className="SearchBoxArea" sx={{ position: 'absolute', top: 40, left: '50%', transform: 'translateX(-50%)', width: '65%', zIndex: 3 }}>
          <SearchAutoComplete />
        </Box>
        {audienceList?.length > 0 &&
          <Box className="CrumbSliderArea" sx={{ position: 'absolute', bottom: 40, left: '50%', transform: 'translateX(-50%)', width: '95%', zIndex: 2 }}>
            <AudienceSlider
              crumbDetails={null}
              audienceList={audienceList}
              handleDeactivateAudience={handleDeactivateAudience}
              handleActivateAudience={handleActivateAudience}
              handleDeleteAudience={handleDeleteAudience}
              handleAudienceRefresh={handleAudienceRefresh}
              handleAudienceRename={handleAudienceRename}
              handleAudienceDelete={handleAudienceDelete}
              updateAudiences={updateAudiences}
              comefrom={'favourite-page'}
            />
          </Box>
        }
      </Box>
      <Box className="DetailsArea" sx={{ height: '100vh', position: 'fixed', top: 0 }}>
        <HomepageDetails />
      </Box>
    </Box>

    {activateTopUpCreditModalState &&
        <ActivateTopUpCreditModal
            activateTopUpCreditModalState={activateTopUpCreditModalState}
            setActivateTopUpCreditModalState={setActivateTopUpCreditModalState}
        />
    }
  </>);
}

export default FavouriteCardsPage;