import {React, useState} from "react";
import {Button, Card, CardActions, CardContent, CardMedia, Typography, Menu, MenuItem, Box} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {getPermission} from "../../../../utils/cryptograpgy";
import RenameAudienceModal from "../../modal/rename-audiencename-modal/RenameAudienceModal";
import {More, Profile2User} from "iconsax-react";
import {useDispatch, useSelector} from "react-redux";
import {Export, Location, Setting4} from "iconsax-react";
import styles from './CampaignCard.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { setCampaignAudiences, setTotalSelectedAudience } from "../../../../redux/slices/audience";


function CampaignCard({isClose, audienceDetails}) {

    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const menuOpen = Boolean(menuAnchorEl);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const renameCardPermission = getPermission('rename_card');
    const [renameAudienceModalState, setRenameAudienceModalState] = useState(false);
    const {currentTheme} = useSelector((state) => state.theme);
    const { campaignAudiences, totalSelectedAudience } = useSelector((state) => state.audience);

    const handleClose = (e, reason) => {
        dispatch(setTotalSelectedAudience(0));
        dispatch(setCampaignAudiences([]));
        if (reason !== "backdropClick") {
            if (audienceDetails && audienceDetails.audienceId) {

                navigate('/audience/' + audienceDetails.audienceId);
            } else {
                navigate('/Home');
            }
        }
    };

    const addAudience = (id) => {

        const updatedCampaignAudiences = campaignAudiences.map(audience => {
            if (audience.id === id) {
                return { ...audience, isAdd: true };
            }

            return audience;
        });

        let audienceToAdd = campaignAudiences.find(audience => audience.id === id);

        const totalAudienceCountToAdd = audienceToAdd.totalAudienceCount;

        dispatch(setTotalSelectedAudience(totalSelectedAudience + totalAudienceCountToAdd));
        dispatch(setCampaignAudiences(updatedCampaignAudiences));
    };


    const removeAudience = (id) => {

        // const updatedCampaignAudiences = campaignAudiences.map(audience => {
        //     if (audience.id === id) {
        //         return { ...audience, isAdd: false };
        //     }
        //
        //     return audience;
        // });

        // Remove the audience with the specified id from the campaignAudiences
        const updatedCampaignAudiences = campaignAudiences.filter(audience => audience.id !== id);

        let audienceToAdd = campaignAudiences.find(audience => audience.id === id);

        const totalAudienceCountToSub = audienceToAdd.totalAudienceCount;

        dispatch(setTotalSelectedAudience(totalSelectedAudience - totalAudienceCountToSub));
        dispatch(setCampaignAudiences(updatedCampaignAudiences));
    };

    return (
        <>
            <Card
                className="card"
                style={{
                    height: "125px",
                    width: "calc(100% - 45px)",
                    background: currentTheme === 'light' ? '#F1F1F1' : '#0B0E24',
                    border: currentTheme === 'light' ? '1px solid #D9D9D9' : '1px solid #3955BF',
                    borderRadius: "15px",
                    boxShadow: currentTheme === 'dark' ? '#a1a19b 0px 0px 1px' : '#000000 0px 0px 1px'
                }}
            >
                <CardMedia
                    //onClick={() => handleAudienceClick(audience)}
                    component="div"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        // justifyContent: 'space-between',
                        position: 'relative'
                    }}
                >
                    <img
                        src={audienceDetails.imagePath}
                        alt="Contemplative Reptile"
                        style={{
                            width: "80px",
                            height: "50px",
                            borderRadius: "12px",
                            opacity: "0px",
                            maxHeight: "100px",
                            objectFit: "cover",
                            margin: "5px 5px"
                        }}
                    />
                    <div style={{display: 'flex', flexDirection: 'column', padding: "0 10px"}}>
                        <Typography
                            variant="body1"
                            title={audienceDetails.name}
                            style={{
                                fontFamily: 'Manrope, Arial, sans-serif',
                                fontSize: '18px',
                                fontWeight: 700,
                                lineHeight: '32.78px',
                                textAlign: 'left',
                                color: currentTheme === 'dark' ? '#ffffff' : undefined // Font color white if dark theme
                            }}
                        >
                            { audienceDetails.name.length > 16 ? `${audienceDetails.name.substring(0, 15)}..` : audienceDetails.name}
                        </Typography>
                        <div style={{display: "flex", gap: "5px"}}>
                            <span className={`${styles.CampaignCardLocation ?? ''}`}>
                                <Location size="14"/>
                            </span>
                            <Typography
                                variant="body2"
                                style={{
                                    fontFamily: 'Manrope, Arial, sans-serif',
                                    fontSize: '13px',
                                    fontWeight: 10,
                                    lineHeight: '16.39px',
                                    textAlign: 'left',
                                    color: '#9A9CA7' // Font color white if dark theme
                                }}
                            >
                                Locations {/*{audience.totalLocationPin}*/}
                                <br/>
                                <Typography
                                    variant="body3"
                                    style={{
                                        fontSize: '16px',
                                        width: '46px',
                                        height: '19px',
                                        top: '256px',
                                        left: '1223px',
                                        gap: '0px',
                                        opacity: '0px',
                                        color: currentTheme === 'dark' ? '#ffffff' : '#000000' // Font color white if dark theme
                                    }}
                                >
                                    {audienceDetails?.totalLocationPin ? audienceDetails.totalLocationPin : audienceDetails.locationPin}
                                </Typography>
                            </Typography>
                        </div>
                    </div>
                </CardMedia>
                <CardContent className="fiCardContent" style={{padding: "5px"}}>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div style={{display: "flex", gap: "5px"}}>
                            <Box className={`${styles.CampaignCardLocation ?? ''}`}>
                                <Profile2User color={'#3955BF'} size="15"/>
                            </Box>
                            <Typography
                                variant="body2"
                                className="fiCardContentTextSecondary"
                                component="p"
                                style={{
                                    color: '#9A9CA7' // Font color white if dark theme
                                }}
                            >
                                Total Unique Active Users
                                <br/>
                                <span
                                    className="fiCardContentTextSecondary"
                                    style={{
                                        fontFamily: 'Tomorrow',
                                        fontSize: '16px',
                                        fontWeight: 500,
                                        lineHeight: '19.2px',
                                        textAlign: 'left',
                                        color: currentTheme === 'dark' ? '#ffffff' : '#000000' // Font color white if dark theme
                                    }}
                                >
                                     { audienceDetails?.totalAudienceCount? ( audienceDetails.totalAudienceCount).toLocaleString() : (audienceDetails.audience).toLocaleString() }
                        </span>
                            </Typography>
                        </div>

                        {isClose && (
                            <span className={`${styles.btnCloseBorder ?? ''}`}>
                             <Button onClick={handleClose}>
                              <CloseIcon sx={{color: "red"}}/>
                             </Button>
                            </span>
                        )}

                        {!isClose && (
                            <>
                                {/* Block 2 */}
                                {audienceDetails?.isAdd ? (
                                    // Render close button if audienceDetails.isAdd is true
                                    <span className={`${styles.btnCloseBorder ?? ''}`}>
                                     <Button onClick={() => removeAudience(audienceDetails.id)}>
                                      <CloseIcon sx={{color: "red"}}/>
                                     </Button>
                                    </span>
                                ) : (
                                    // Render add button if audienceDetails.isAdd is false or null
                                    <span className={`${styles.btnAddBorder ?? ''}`}>
                                      <Button onClick={() => addAudience(audienceDetails.id)}>
                                       <AddIcon sx={{color: "white"}}/>
                                      </Button>
                                    </span>
                                )}
                                {/* Block 3 */}
                            </>
                        )}
                    </div>
                </CardContent>
            </Card>

        </>
    );
}

export default CampaignCard;