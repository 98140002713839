import React, { useCallback, useState, useEffect } from "react";
import {loadStripe} from '@stripe/stripe-js';
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { useLocation } from "react-router-dom";
import StripeService from "../../services/api/stripe.service";
import {setMessage} from "../../redux/slices/message";
import {errorMessage} from "../../config/config";
import {setMessageSnackbarStateState} from "../../redux/slices/common";
import {useDispatch, useSelector} from "react-redux";
import {Box} from "@mui/system";
import { stripeClientSecret } from "../../config/config";

const stripePromise = loadStripe(stripeClientSecret);

function CheckoutForm() {

    const dispatch = useDispatch();
    const { state } = useLocation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { user } = useSelector((state) => state.auth.auth);
    const userFromLocalStorage = JSON.parse(localStorage.getItem('user'));
    const { creditAmount } = state ?? {};

    const fetchClientSecret = useCallback(() => {

        const userId = user?.id || userFromLocalStorage?.id;

        const queryCalcParams = [
            `userId=${userId}`,
            `totalAmount=${creditAmount}`,
        ];

        return StripeService.getCheckout(queryCalcParams.join('&')).then(
            (data) => {
            //const  lof = response.clientSecret;
                if (data?.results) {
                    return data.results.clientSecret;
                }
            return data.clientSecret; // Assuming response contains the client secret directly
        }, (error) => {
                console.log(error);
                setIsSubmitting(false);

                if (error?.response?.data) {
                    dispatch(setMessage({
                        type: "error",
                        title: error.response.data.message ? error.response.data.message : errorMessage,
                    }));
                } else {
                    dispatch(setMessage({
                        type: "error",
                        title: errorMessage,
                    }));
                }
                dispatch(setMessageSnackbarStateState(true));
                setIsSubmitting(false);
                return Promise.reject();
            });
    }, []);


    const options = {fetchClientSecret};

    return (
        <Box  style={{ backgroundColor: 'white', height: '1000px' , paddingTop: '50px'}}>
            <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={options}
            >
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
        </Box>
    );
}




export default CheckoutForm;